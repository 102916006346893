import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoRootModule } from './transloco-root.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedRoutingModule } from './shared-routing.module';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { NameFormatPipe } from './utils/pipes/name-format.pipe';


@NgModule({
  imports: [
    CommonModule,
    SharedRoutingModule,
    ReactiveFormsModule,
    TranslocoRootModule,
  ],
  declarations: [
    AboutUsComponent,
    NameFormatPipe,
  ],
  exports: [
    TranslocoRootModule,
    ReactiveFormsModule,
    AboutUsComponent,
    NameFormatPipe
  ],
  providers: [
    NameFormatPipe,
  ],
})
export class SharedModule { }
