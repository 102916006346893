<footer class="footer">
  <div class="row align-items-center justify-content-xl-between">
    <div class="col-xl-4">
      <div class="copyright text-center text-xl-left text-muted">
        {{ appName + ' v' + version }} &copy; {{ anio | date: "yyyy" }} <a href="{{webUrl}}"
          class="font-weight-bold ml-1" target="_blank">{{ ciaName }}</a>
      </div>
    </div>

    <div *ngIf="enableSocialLink" class="col-xl-4 text-center">
      <a *ngIf="TWITTER_LINK != ''" href="{{TWITTER_LINK}}" target="_blank">
        <i class="fab fa-twitter"></i>
      </a>
      &nbsp;
      <a *ngIf="YOUTUBE_LINK != ''" href="{{YOUTUBE_LINK}}" target="_blank">
        <i class="fab fa-youtube"></i>
      </a>
      &nbsp;
      <a *ngIf="FACEBOOK_LINK != ''" href="{{FACEBOOK_LINK}}" target="_blank">
        <i class="fab fa-facebook"></i>
      </a>
      &nbsp;
      <a *ngIf="INSTAGRAM_LINK != ''" href="{{INSTAGRAM_LINK}}" target="_blank">
        <i class="fab fa-instagram"></i>
      </a>
      &nbsp;
      <a *ngIf="LINKEDIN_LINK != ''" href="{{LINKEDIN_LINK}}" target="_blank">
        <i class="fab fa-linkedin"></i>
      </a>
    </div>

    <div class="col-xl-4">
      <ul class="nav nav-footer justify-content-center justify-content-xl-end">
        <li class="nav-item">
          <a [routerLink]="['/'+aboutPath]" class="nav-link">{{ 'label.about-us' | transloco }}</a>
        </li>
        <!--li class="nav-item">
          <a [routerLink]="['/'+supportPath]" class="nav-link">{{ 'label.support' | transloco }}</a>
        </li-->
        <li class="nav-item">
          <a [routerLink]="['/'+privacyPolicyPath]" class="nav-link" target="_blank">{{ 'label.privacy-policy' |
            transloco }}</a>
        </li>
      </ul>
    </div>
  </div>
</footer>