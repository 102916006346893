<div class="main-content">
  <nav class="navbar navbar-top navbar-horizontal navbar-expand-md navbar-dark">
    <div class="container px-4">
      <a class="navbar-brand" routerLinkActive="active" [routerLink]="['/'+mainPath]">
        <img src="assets/img/brand/white.png" />
      </a>
      <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
        aria-controls="sidenav-collapse-main">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
        <!-- Collapse header -->
        <div class="navbar-collapse-header d-md-none">
          <div class="row">
            <div class="col-6 collapse-brand">
              <a routerLinkActive="active" [routerLink]="['/'+mainPath]">
                <img src="assets/img/brand/normal.png">
              </a>
            </div>
            <div class="col-6 collapse-close">
              <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed">
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
        <!-- Navbar items -->
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link nav-link-icon" routerLinkActive="active" [routerLink]="['/'+mainPath]">
              <i class="ni ni-bag-17"></i>
              <span class="nav-link-inner--text">{{ 'label.store' | transloco }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-icon" routerLinkActive="active" [routerLink]="['/'+registerPath]">
              <i class="ni ni-circle-08"></i>
              <span class="nav-link-inner--text">{{ 'register.title' | transloco }}</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link nav-link-icon" routerLinkActive="active" [routerLink]="['/'+loginPath]">
              <i class="ni ni-key-25"></i>
              <span class="nav-link-inner--text">{{ 'login.title' | transloco }}</span>
            </a>
          </li>
          <li class="nav-item ml-auto">
            <a class="nav-link nav-link-icon" (click)="openLanguageModal()">
              <i class="ni ni-world"></i>
              <span class="nav-link-inner--text">{{ 'language.title' | transloco }}</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <router-outlet></router-outlet>
</div>
<footer class="py-5">
  <div class="container">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-4">
        <div class="copyright text-center text-xl-left text-muted">
          &copy; {{ anio | date: "yyyy" }} <a href="{{webUrl}}" class="font-weight-bold ml-1" target="_blank">{{ ciaName
            }}</a>
        </div>
      </div>
      <div *ngIf="enableSocialLink" class="col-xl-4 text-center">
        <a *ngIf="TWITTER_LINK != ''" href="{{TWITTER_LINK}}" target="_blank">
          <i class="fab fa-twitter"></i>
        </a>
        &nbsp;
        <a *ngIf="YOUTUBE_LINK != ''" href="{{YOUTUBE_LINK}}" target="_blank">
          <i class="fab fa-youtube"></i>
        </a>
        &nbsp;
        <a *ngIf="FACEBOOK_LINK != ''" href="{{FACEBOOK_LINK}}" target="_blank">
          <i class="fab fa-facebook"></i>
        </a>
        &nbsp;
        <a *ngIf="INSTAGRAM_LINK != ''" href="{{INSTAGRAM_LINK}}" target="_blank">
          <i class="fab fa-instagram"></i>
        </a>
        &nbsp;
        <a *ngIf="LINKEDIN_LINK != ''" href="{{LINKEDIN_LINK}}" target="_blank">
          <i class="fab fa-linkedin"></i>
        </a>
      </div>
      <div class="col-xl-4">
        <ul class="nav nav-footer justify-content-center justify-content-xl-end">
          <li class="nav-item">
            <a [routerLink]="['/'+aboutPath]" class="nav-link">{{ 'label.about-us' | transloco }}</a>
          </li>
          <!--li class="nav-item">
            <a [routerLink]="['/'+supportPath]" class="nav-link">{{ 'label.support' | transloco }}</a>
          </li-->
          <li class="nav-item">
            <a [routerLink]="['/'+privacyPolicyPath]" class="nav-link" target="_blank">{{ 'label.privacy-policy' |
              transloco }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>