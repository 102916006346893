import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { PublicLayoutComponent } from "./layouts/public-layout/public-layout.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AppRoutingModule } from "./app.routing";
import { ComponentsModule } from "./components/components.module";
import { ConfirmModalComponent } from "./shared/utils/modals/confirm-modal/confirm-modal.component";
import { AlertModalComponent } from "./shared/utils/modals/alert-modal/alert-modal.component";
import { MdbModalModule } from "mdb-angular-ui-kit/modal";
import { MatGridListModule } from "@angular/material/grid-list";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { SharedModule } from "./shared/shared.module";
import { PaymentDetailComponent } from "./pages/public/payment/payment-detail/payment-detail.component";
import { NgIdleKeepaliveModule } from "@ng-idle/keepalive";
import { SelectLanguageModalComponent } from "./shared/utils/modals/select-language-modal/select-language-modal.component";
import { SelectCompanyComponent } from "./shared/utils/modals/select-company/select-company.component";
import { AlertLinkModalComponent } from "./shared/utils/modals/alert-link-modal/alert-link-modal.component";
import { RecaptchaV3Module, RECAPTCHA_V3_SITE_KEY } from "ng-recaptcha";
import { environment } from "src/environments/environment";
import { LicenceDetailComponent } from "./shared/utils/modals/licence-detail/licence-detail.component";
import { TicketDetailComponent } from "./shared/utils/modals/ticket-detail/ticket-detail.component";
import { TicketPhaseDetailComponent } from "./shared/utils/modals/ticket-phase-detail/ticket-phase-detail.component";
import { ManageRolesComponent } from "./shared/utils/modals/manage-roles/manage-roles.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    MdbModalModule,
    SharedModule,
    MatGridListModule,
    ReactiveFormsModule,
    NgIdleKeepaliveModule.forRoot(),
    RecaptchaV3Module,
    MatAutocompleteModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    PublicLayoutComponent,
    AuthLayoutComponent,
    ConfirmModalComponent,
    SelectLanguageModalComponent,
    AlertModalComponent,
    PaymentDetailComponent,
    SelectCompanyComponent,
    AlertLinkModalComponent,
    LicenceDetailComponent,
    TicketDetailComponent,
    TicketPhaseDetailComponent,
    ManageRolesComponent,
  ],
  providers: [
    DatePipe,
    CurrencyPipe,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
