import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';
import { SessionService } from './services/session.service';
import { Commons } from './shared/utils/commons';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  KEEPALIVE_TIME: number = 3
  IDLE_TIME: number = (environment.sessionTimeMins * 60)

  constructor(
    private idle: Idle,
    keepalive: Keepalive,
    private router: Router,
    private sessionService: SessionService,
  ) {
    keepalive.interval(this.KEEPALIVE_TIME);
    keepalive.onPing.subscribe(() => {
      if (Commons.isGAuthCredentials()) {
        const session = Commons.sessionObject()
        const fechaActual = Date.now();
        // Diferencia en milisegundos
        const diffMs = fechaActual - session.time;
        // Diferencia en minutos
        const diffMins = Math.floor(diffMs / 60000);
        if(diffMins > this.IDLE_TIME){
          //Force close session because google token expires in 1 hour
          this.closeSessionExpToken()
        }
      } else {
        Commons.sessionRenew()
      }
    });

    idle.setIdle(this.IDLE_TIME); // Define el tiempo de inactividad en segundos.
    idle.setTimeout(1); // Define el tiempo de espera en segundos antes de que se dispare el evento timeout.
    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => { });
    idle.onTimeout.subscribe(() => {
      this.closeSession()

    });
    idle.onIdleStart.subscribe(() => { });
    idle.onTimeoutWarning.subscribe((countdown) => { });

    this.sessionService.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn) {
        idle.watch()
      } else {
        idle.stop();
      }
    })
  }

  reset() {
    this.idle.watch();
  }

  ngOnInit(): void {
    // right when the component initializes, start reset state and start watching
    this.reset();
  }

  closeSession() {
    if (Commons.sessionIsOpen()) {
      this.sessionService.setUserLoggedIn(false);
      Commons.sessionClose()
      this.router.navigate([Commons.PATH_LOGIN], { queryParams: { arg: 'closed' } })
    }
  }

  closeSessionExpToken() {
    if (Commons.sessionIsOpen()) {
      this.sessionService.setUserLoggedIn(false);
      Commons.sessionClose()
      this.router.navigate([Commons.PATH_LOGIN], { queryParams: { arg: 'expToken' } })
    }
  }

}
