<nav class="navbar navbar-vertical navbar-expand-md navbar-light bg-white" id="sidenav-main">
  <div class="container-fluid">
    <!-- Toggler -->
    <button class="navbar-toggler" type="button" (click)="isCollapsed=!isCollapsed"
      aria-controls="sidenav-collapse-main">
      <span class="navbar-toggler-icon"></span>
    </button>
    <!-- Brand -->
    <a class="navbar-brand pt-0" routerLinkActive="active" [routerLink]="['/'+mainPath]">
      <img src="./assets/img/brand/normal.png" class="navbar-brand-img" alt="...">
    </a>
    <!-- User -->
    <ul class="nav align-items-center d-md-none">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a class="nav-link" role="button" ngbDropdownToggle>
          <div class="media align-items-center">
            <span class="avatar avatar-sm rounded-circle">
              <i *ngIf="!sessionIsOpen" class="fa fa-user"></i>
              <img *ngIf="sessionIsOpen" alt="Image placeholder" [src]="avatarUrl">
            </span>
          </div>
        </a>
        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <a *ngIf="sessionIsOpen" routerLinkActive="active" [routerLink]="['/'+profilePath]" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>{{ 'label.profile' | transloco }}</span>
          </a>
          <button class="dropdown-item" (click)="openLanguageModal()">
            <span class="avatar avatar-xs rounded-circle">
              <img alt="Image Lang" src="{{ langIcon }}">
            </span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;{{ 'language.title' | transloco }}</span>
          </button>
          <!--a routerLinkActive="active" [routerLink]="['/'+supportPath]" class="dropdown-item">
            <i class="ni ni-support-16"></i>
            <span>{{ 'label.support' | transloco }}</span>
          </a-->
          <div class="dropdown-divider"></div>
          <button *ngIf="sessionIsOpen" class="dropdown-item" (click)="closeSession()">
            <i class="ni ni-user-run"></i>
            <span>{{ 'label.close' | transloco }}</span>
          </button>
          <a *ngIf="!sessionIsOpen" [routerLink]="['/'+loginPath]" class="dropdown-item">
            <i class="fa fa-door-open"></i>
            <span>{{ 'login.button' | transloco }}</span>
          </a>
        </div>
      </li>
    </ul>
    <!-- Collapse -->
    <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main">
      <!-- Collapse header -->
      <div class="navbar-collapse-header d-md-none">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a routerLinkActive="active" [routerLink]="['/'+mainPath]">
              <img src="./assets/img/brand/normal.png">
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" (click)="isCollapsed=!isCollapsed">
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <!-- Heading -->
      <h6 class="navbar-heading text-muted">{{ 'label.menu' | transloco }}</h6>
      <!-- Navigation -->
      <ul class="navbar-nav">
        <li *ngFor="let pageItem of menuItems" class="{{pageItem.class}} nav-item">
          <a routerLinkActive="active" [routerLink]="['/'+pageItem.path]" class="nav-link">
            <i class="ni {{pageItem.icon}}"></i>
            {{ pageItem.title | transloco }}
          </a>
        </li>
        <div *ngIf="sessionIsOpen && !isSuperUser">
          <li *ngFor="let pageItem of publicItems" class="{{pageItem.class}} nav-item">
            <a routerLinkActive="active" [routerLink]="['/'+pageItem.path]" class="nav-link">
              <i class="ni {{pageItem.icon}}"></i>
              {{ pageItem.title | transloco }}
            </a>
          </li>
        </div>

      </ul>

      <div *ngIf="sessionIsOpen && isSuperUser">
        <!-- Divider -->
        <hr class="my-3">
        <!-- Heading -->
        <h6 class="navbar-heading text-muted">{{ 'label.administration' | transloco }}</h6>
        <!-- Navigation -->
        <ul class="navbar-nav">
          <li *ngFor="let pageItem of adminItems" class="{{pageItem.class}} nav-item">
            <a routerLinkActive="active" [routerLink]="['/'+pageItem.path]" class="nav-link">
              <i class="ni {{pageItem.icon}}"></i>
              {{ pageItem.title | transloco }}
            </a>
          </li>
        </ul>
      </div>

      <div *ngIf="sessionIsOpen">
        <!-- Divider -->
        <hr class="my-3">
        <!-- Heading -->
        <h6 class="navbar-heading text-muted">{{ 'label.settings' | transloco }}</h6>
        <!-- Navigation -->
        <ul class="navbar-nav">
          <li *ngFor="let pageItem of settingsItems" class="{{pageItem.class}} nav-item">
            <a routerLinkActive="active" [routerLink]="['/'+pageItem.path]" class="nav-link">
              <i class="ni {{pageItem.icon}}"></i>
              {{ pageItem.title | transloco }}
            </a>
          </li>
        </ul>
      </div>


    </div>
  </div>
</nav>