import { Component, OnInit } from '@angular/core';
import { Commons } from 'src/app/shared/utils/commons';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  anio: Date = new Date();
  ciaName: string = Commons.SDX
  privacyPolicyPath: string = Commons.PATH_TERMS + '/' + Commons.TERM_CODES[0].code
  aboutPath: string = Commons.PATH_ABOUT
  supportPath: string = Commons.PATH_SUPPORT
  version = environment.appVersion
  appName =  environment.appName
  webUrl = environment.webUrl

  YOUTUBE_LINK = environment.social.youtube
  INSTAGRAM_LINK = environment.social.instagram
  TWITTER_LINK = environment.social.x
  FACEBOOK_LINK = environment.social.facebook
  LINKEDIN_LINK = environment.social.linkedin

  constructor() { }

  ngOnInit() {
  }

  get enableSocialLink(): boolean {
    const socialLinks = [this.YOUTUBE_LINK, this.INSTAGRAM_LINK, this.TWITTER_LINK, this.FACEBOOK_LINK, this.LINKEDIN_LINK];
    return socialLinks.some(link => link !== '');
  }

}
