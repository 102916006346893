
export const environment = {
  appVersion: '1.1.5',
  webUrl: 'https://www.softdirex.com',
  baseUrl: 'https://escritorio-dev.softdirex.com',
  production: true,
  appName: 'Escritorio Empresas',
  orgName: 'Softdirex',
  apiCoreUrl: 'https://core-dev.softdirex.com/v1',
  pwdRegex: '^[\x20-\x7E]{8,30}$',
  rutRegex: '\\b[0-9|.]{1,10}\\-[K|k|0-9]',
  passportRegex: '[a-zA-Z0-9-]{6,100}',
  dniRegex: '[a-zA-Z0-9-]{6,100}',
  documentDataRegex: '[a-zA-Z0-9-]{6,100}',
  namesRegex: '[a-zA-Z\u00C0-\u00ff-\' ]{2,90}',
  phonesRegex: '[0-9+() -]{8,35}',
  addressRegex: '[a-zA-Z\u00C0-\u00ff-.@ 0-9]{1,90}',
  rrssRegex: '[a-zA-Z-/:.#@_0-9%=?]{1,90}',
  obsRegex: '[a-zA-Z/%\u00C0-\u00ff-.,\'\n": 0-9]{2,1000}',
  aboutRegex: '[a-zA-Z/¡!()@=?¿%\u00C0-\u00ff-.,\'\n": 0-9]{2,5000}',
  localeKey: 'NIKqPYrGejeDFCcRC0Y82V',
  sessionTimeMins: 40,
  supportMail: 'jleiva@softdirex.com',
  enableWebPay: true,
  recaptcha: {
    siteKey: '6LcSKvEkAAAAAFfhUa9wcIhCFAd_P_yZFwN6lRNj',
  },
  social: {
    youtube: 'https://www.youtube.com/@softdirexsolucionesinforma2851',
    instagram: 'https://www.instagram.com/softdirex_/',
    x: 'https://twitter.com/softdirex',
    facebook: 'https://web.facebook.com/Softdirex/',
    linkedin: 'www.linkedin.com/company/softdirex'
  },
  googleClientId: '299928096210-g4r6tonbi1g9a47f798cum1muuo32e0q.apps.googleusercontent.com'
};
